import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import holdingLogo from '../images/cutting-edge.svg'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="layout">
      <img src={holdingLogo} alt="Cutting Edge" className="holdingLogo" />
    </div>
  </Layout>
)

export default IndexPage
